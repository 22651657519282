<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel1">
              Masukkan Nomor Surat
            </h4>
            <button
              type="button"
              @click="close"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onEdit="doEdit"
              :forbidEdit="false"
              :forbidDelete="true"
            />
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-danger" @click="action">Ya</button>
            <button type="button" class="btn btn-default" data-dismiss="modal" @click="close">Tidak</button>-->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Table from "@/components/table/table";
export default {
  components: {
    Table,
  },
  name: "deleteConfirmation",
  data() {
    return {
      headers: {
        document_number: {
          label: "Nomor Surat",
          sortable: false,
        },
        document_date: {
          label: "Tanggal",
          sortable: true,
          type: "date",
        },
        subject: {
          label: "Perihal",
          sortable: true,
        },
      },
    };
  },
  computed: {
    items() {
      return this.$store.state.documentIn.items.length === 0 ||
        this.$store.state.documentIn.items.length != undefined
        ? []
        : this.$store.state.documentIn.items.items;
    },
    filters() {
      return this.$store.state.documentIn.items.length === 0 ||
        this.$store.state.documentIn.items.length != undefined
        ? {}
        : this.$store.state.documentIn.items.filters;
    },
    state() {
      return this.$store.state.documentIn;
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get(val) {
      this.$store.dispatch("documentIn/getDocumentIn", val);
    },
    close() {
      this.$emit("close");
    },
    doEdit(val) {
      // this.$store.dispatch("documentIn/onEdit", val);
      this.$store.dispatch("documentIn/onEdit", val);
      // this.$store.dispatch("documentIn/onDetail", val);
      this.$emit("close");
    },
    // action() {
    //   this.$emit("action");
    // }
  },
};
</script>
<style>
.modal-backdrop {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  position: relative !important;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>